import React from 'react';
import NextImage, { ImageProps } from 'next/image';
import ENDPOINTS from '../../endpoints.json';

type contentfulLoaderProps = {
  src: string;
  quality: number;
  width: number;
};
function contentfulLoader({ src, quality, width }: contentfulLoaderProps) {
  const params = [`w=${width}`];
  if (quality) {
    params.push(`q=${quality}`);
  }

  const regex = /^(?:[a-z]+:)?/;
  // check if the url is absolute
  const isAbsoluteUrl = regex.test(src);

  // convert url to absolute
  const absolutePath =
    isAbsoluteUrl || process.env.BUILD_ENV === 'local'
      ? src
      : `${ENDPOINTS.website[process.env.BUILD_ENV]}${src}`;

  return `${absolutePath}?${params.join('&')}`;
}

const Image = (props: ImageProps) => {
  const { src, width } = props;
  return (
    <NextImage
      loader={contentfulLoader}
      placeholder={typeof width === 'number' && width > 100 ? 'blur' : 'empty'}
      blurDataURL={src as string}
      {...props}
    />
  );
};

export default Image;
