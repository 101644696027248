import React from 'react';
import styled from 'styled-components';

import { LinkFragment } from '../schemas/api';

import Icon, { IconName } from './Icon';
import Button, { ButtonSize } from '../styles/button';
import LinkJSX from './Link';

import { columns } from '../styles/grid';

import TYPO from '../styles/typography';
import COLORS from '../styles/colors';
import NavigationItem from './NavigationItem';

const Container = styled.div`
  ${columns(1, 12)};
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  padding: 12px;
`;

const Buttons = styled.ul`
  ${columns(1, 12)};
  justify-content: center;
  display: flex;
  align-items: center;
  gap: 12px;
  flex-wrap: wrap;
`;

const Byline = styled.div`
  ${TYPO.p3};
  color: ${COLORS.shades.s300.css};
  text-align: center;
`;

type CTAButtonsProps = {
  links?: LinkFragment[] | null;
  byline?: string;
  size?: ButtonSize;
};

function CTAButtons({ links, byline, size }: CTAButtonsProps) {
  if (!links) return null;
  return (
    <Container className="call-to-actions">
      <Buttons>
        {links.map((d) => {
          if (d.variant?.toLowerCase() === 'navigation') {
            return <NavigationItem link={d} key={d.label} />;
          }
          return (
            <li key={d.label}>
              <LinkJSX link={d}>
                <Button
                  $variant={
                    (d.variant?.toLowerCase() as
                      | 'default'
                      | 'secondary'
                      | 'light') || 'default'
                  }
                  $size={size}
                >
                  {d.icon && (
                    <Icon name={d.icon as IconName} width={12} height={12} />
                  )}
                  {d.label}
                </Button>
              </LinkJSX>
            </li>
          );
        })}
      </Buttons>
      {byline && <Byline>{byline}</Byline>}
    </Container>
  );
}

export default CTAButtons;
