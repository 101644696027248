import React, { useContext } from 'react';
import Head from 'next/head';

import { PageContext } from './PageProvider';

import ENDPOINTS from '../../endpoints.json';

// import COLORS from '../styles/colors';

const url = process.env.BUILD_ENV
  ? ENDPOINTS.website[process.env.BUILD_ENV]
  : ENDPOINTS.website.local;

type MetaProps = {
  title: string;
  description?: string;
  image?: { url?: string; width?: number; height?: number };
  indexable: boolean;
  slug?: string;
};

const Meta = ({ title, description, image, indexable, slug }: MetaProps) => {
  const { generic } = useContext(PageContext);
  return (
    <Head>
      <title key="title">{title}</title>
      <meta key="x-ua" httpEquiv="x-ua-compatible" content="ie=edge" />
      <meta
        key="viewport"
        name="viewport"
        content="width=device-width,initial-scale=1"
      />
      <meta key="author" name="author" content={generic.siteTitle} />
      <meta
        key="description"
        name="description"
        content={description || generic.description}
      />
      <meta
        key="keywords"
        name="keywords"
        content={generic.keywords.join(' ')}
      />
      <meta
        name="locale"
        key="og.locale"
        property="og:locale"
        content="en_gb"
      />
      <meta name="type" key="og.type" property="og:type" content="website" />
      <meta
        key="og.site_name"
        property="og:site_name"
        content={generic.siteTitle}
      />
      <meta name="title" key="og.title" property="og:title" content={title} />
      <meta
        key="og.url"
        property="og:url"
        content={slug ? `${generic.url}/${slug}` : generic.url}
      />
      <meta
        name="image"
        key="og.image"
        property="og:image"
        content={image?.url || generic.ogImage.url}
      />
      <meta
        key="og.image.width"
        property="og:image:width"
        content={image?.width?.toString() || '1200'}
      />
      <meta
        key="og.image.height"
        property="og:image:height"
        content={image?.height?.toString() || '630'}
      />
      <meta
        key="og.description"
        property="og:description"
        content={description || generic.description}
      />

      <meta
        key="twitter.card"
        name="twitter:card"
        content="summary_large_image"
      />
      <meta key="twitter.title" name="twitter:title" content={title} />
      <meta
        key="twitter.description"
        name="twitter:description"
        content={description || generic.description}
      />
      <meta
        key="twitter.image"
        name="twitter:image"
        content={image?.url || generic.twitterImage.url}
      />

      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href={`${url}/favicons/apple-touch-icon.png`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href={`${url}/favicons/favicon-32x32.png`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href={`${url}/favicons/favicon-16x16.png`}
      />
      <link rel="icon" type="image/svg" href={`${url}/favicons/favicon.svg`} />
      <link
        rel="manifest"
        href={`${url}/favicons/site.webmanifest`}
        crossOrigin="use-credentials"
      />
      <link
        rel="mask-icon"
        href={`${url}/favicons/safari-pinned-tab.svg`}
        color="#6448e0"
      />
      <link rel="shortcut icon" href={`${url}/favicons/favicon.ico`} />
      <meta name="msapplication-TileColor" content="#da532c" />
      <meta
        name="msapplication-config"
        content={`${url}/favicons/browserconfig.xml`}
      />
      <meta name="theme-color" content="#ffffff" />
      {process.env.BUILD_ENV === 'staging' && <base href={url} />}
      {(!indexable || process.env.BUILD_ENV !== 'production') && (
        <meta name="robots" content="noindex" />
      )}
      {Array.isArray(generic.otherMeta) &&
        generic.otherMeta.map((d) => {
          const parsed = d.split(':');
          if (parsed.length !== 2) return null;
          const [name, content] = parsed;
          return (
            <meta key={`${name}-${content}`} name={name} content={content} />
          );
        })}
    </Head>
  );
};

export default Meta;
