import styled from 'styled-components';

import COLORS from './colors';
import TYPO from './typography';

export const Tag = styled.div`
  display: flex;
  padding: 1px 3px;
  border-radius: 3px;
  background: ${COLORS.success.light.css};
  color: ${COLORS.success.regular.css};
  ${TYPO.preheading};
`;
