import { line, curveNatural } from 'd3-shape';
import { lerp } from './math';

export const getPathBezier = (
  p1: [number, number],
  p2: [number, number],
  radius?: number
): string => {
  const midY = lerp(p1[1], p2[1], 0.5);
  const midX = lerp(p2[0], p1[0], 0.5);

  const dX = p1[0] < p2[0] ? 1 : -1;
  const dY = p1[1] < p2[1] ? -1 : 1;
  const r = radius || Math.min(Math.abs(p1[1] - midY), Math.abs(p1[0] - midX));

  return [
    {
      command: 'M',
      increment: p1,
    },
    {
      command: 'L',
      increment: [p1[0], midY + r * dY],
    },
    {
      command: 'Q',
      increment: [p1[0], midY],
    },
    {
      command: '',
      increment: [p1[0] + r * dX, midY],
    },
    {
      command: 'L',
      increment: [midX, midY],
    },
    {
      command: 'L',
      increment: [p2[0] - r * dX, midY],
    },
    {
      command: 'Q',
      increment: [p2[0], midY],
    },
    {
      command: '',
      increment: [p2[0], midY - r * dY],
    },
    {
      command: 'L',
      increment: p2,
    },
  ]
    .map((point) => `${point.command}${point.increment.join(' ')}`)
    .join(' ');
};

export const getPath = (
  p1: [number, number],
  length: number,
  height: number,
  borderRadius = 20
) => {
  return [
    {
      command: 'M',
      increment: p1,
    },
    {
      command: 'q',
      increment: [borderRadius, 0],
    },
    {
      command: '',
      increment: [borderRadius, borderRadius],
    },
    {
      command: 't',
      increment: [0, height - borderRadius * 2],
    },
    {
      command: 'q',
      increment: [0, borderRadius],
    },
    {
      command: '',
      increment: [borderRadius, borderRadius],
    },
    {
      command: 't',
      increment: [length - borderRadius * 4, 0],
    },
    {
      command: 'q',
      increment: [borderRadius, 0],
    },
    {
      command: '',
      increment: [borderRadius, -borderRadius],
    },
    {
      command: 't',
      increment: [0, -height + borderRadius * 2],
    },
    {
      command: 'q',
      increment: [0, -borderRadius],
    },
    {
      command: '',
      increment: [borderRadius, -borderRadius],
    },
  ]
    .map((point) => `${point.command}${point.increment.join(' ')}`)
    .join(' ');
};

export const getLine = (p1: [number, number], length: number, count = 8) => {
  const unit = length / (count - 1);
  const displacement = 4;

  const line = [
    {
      command: 'M',
      increment: p1,
    },
    {
      command: 'q',
      increment: [unit / 2, -displacement * 2],
    },
    {
      command: '',
      increment: [unit, 0],
    },
    {
      command: 't',
      increment: [unit, displacement],
    },
    {
      command: 'q',
      increment: [unit / 2, displacement * 2],
    },
    {
      command: '',
      increment: [unit, 0],
    },
    {
      command: 't',
      increment: [unit, -displacement * 2],
    },
    {
      command: 'q',
      increment: [unit / 2, -displacement * 2],
    },
    {
      command: '',
      increment: [unit, 0],
    },
    {
      command: 't',
      increment: [unit, displacement],
    },
    {
      command: 'q',
      increment: [unit / 2, displacement * 2],
    },
    {
      command: '',
      increment: [unit, 0],
    },
  ]
    .map((point) => `${point.command}${point.increment.join(' ')}`)
    .join(' ');

  return line;
};

// equation taken from https://www.nature.com/articles/s41378-020-00211-4
// default values (freqA, freqB and k) render the flow logo
export const lissajous = (
  size = 100,
  freqA = 2,
  freqB = 3,
  k = 2,
  density = 0.01
) => {
  const fx = (t) => {
    return (
      (size / 2) *
        Math.cos(2 * Math.PI * freqA * t + (k * Math.PI) / (4 * freqB)) +
      size / 2
    );
  };
  const fy = (t) => {
    return (size / 2) * Math.cos(2 * Math.PI * freqB * t) + size / 2;
  };

  const points = [];
  for (let angle = 0; angle < 1 + density; angle += density) {
    points.push([fx(angle), fy(angle)]);
  }

  const curve = line().curve(curveNatural);
  return curve(points);
};
