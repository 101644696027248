import React from 'react';

import { animated, easings, useSpring } from '@react-spring/web';

import COLORS from '../styles/colors';

import { lissajous } from '../utils/path';

const VIEWPORT_SIZE = 100;

enum LogoLoaderEasing {
  LINEAR = 'linear',
  BACK = 'easeInOutBack',
  CUBIC = 'easeInOutCubic',
}

type LogoLoaderProps = React.HTMLAttributes<SVGSVGElement> & {
  size?: number;
  strokeWidth?: number;
  color?: string;
  easing?: LogoLoaderEasing;
  delay?: number;
  x?: number;
  y?: number;
};

const LogoLoader = ({
  size = 80,
  strokeWidth = 3,
  color = COLORS.black.css,
  easing = LogoLoaderEasing.BACK,
  delay = 10000,
  ...props
}: LogoLoaderProps) => {
  const [{ k }] = useSpring(
    () => ({
      from: { k: 0 },
      to: { k: 8 },
      loop: true,
      delay,
      config: {
        easing: easings[easing],
        duration: 2000,
      },
    }),
    [easing, delay]
  );

  return (
    <svg
      viewBox={`0 0 ${VIEWPORT_SIZE} ${VIEWPORT_SIZE}`}
      width={size}
      height={size}
      preserveAspectRatio="xMidYMid meet"
      style={{ overflow: 'visible' }}
      role="status"
      aria-label="Loading"
      {...props}
    >
      <animated.path
        d={k.to((d) => lissajous(VIEWPORT_SIZE, 2, 3, (2 + d) % 8)) as any}
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </svg>
  );
};

export default LogoLoader;
