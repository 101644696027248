import React, { memo, useContext } from 'react';
import styled from 'styled-components';

import Link from './Link';

import { PageContext } from './PageProvider';

import useInview from '../hooks/useInview';

import TYPO from '../styles/typography';
import COLORS from '../styles/colors';
import BREAKPOINTS from '../styles/breakpoints';
import { Horizontal } from '../styles/dotted-line';

import Markdown from './Markdown';

import { ReactComponent as InstagramIcon } from '../assets/social-icons/instagram.svg';
import { ReactComponent as LinkedinIcon } from '../assets/social-icons/linkedin.svg';
import { ReactComponent as YoutubeIcon } from '../assets/social-icons/youtube.svg';
import { ReactComponent as TwitterIcon } from '../assets/social-icons/twitter.svg';
import { ReactComponent as LogoLightSvg } from '../assets/logo/logo.svg';

import { Grid, columns, grid } from '../styles/grid';
import { Tag } from '../styles/tag';

const Container = styled(Grid)`
  padding: 120px 0 60px;
  ${BREAKPOINTS.max.medium`
    padding: 72px 0 36px;
  `}
`;

const Content = styled.div`
  ${columns(1, 12)}
  ${grid(12)}
  padding-top:42px;
  padding: 42px 12px 0;
  row-gap: 36px;
`;

const Logo = styled.div`
  display: flex;
  max-width: 130px;
  opacity: 1;
  transition: opacity 0.2s ease;
`;

const LogoLight = styled(LogoLightSvg)`
  width: 100%;
  height: auto;
`;

const Copyright = styled.div`
  & p {
    ${TYPO.footer}
    margin:0;
  }
`;

const LogoWrapper = styled.div`
  ${columns(1, 4)}
  display: flex;
  flex-direction: column;
  gap: 18px;
  ${BREAKPOINTS.max.medium`
    ${columns(1, 3)};
  `}
  ${BREAKPOINTS.max.small`
    ${columns(1, 12)}
    order:10;
    ${Logo} {
      margin: 0 auto;
      justify-content: center;
    }
    ${Copyright} {
      text-align: center;
    }
  `}
`;

const Heading = styled.h1`
  ${TYPO.h3}
`;

const Item = styled.div`
  position: relative;
  display: flex;
  gap: 6px;
  align-items: center;
  ${TYPO.footer}
  ${BREAKPOINTS.max.medium`
    justify-content:center;
  `}
  & a {
    display: block;
  }
  &:hover {
    text-decoration: underline;
    color: ${COLORS.text.regular.css};
  }
`;

const Links = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 8px;
`;

const SocialLinks = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  ${BREAKPOINTS.max.medium`
    margin:0 auto;
  `}
`;

const Columns = styled.div`
  ${columns(6, 7)};
  display: flex;
  gap: 30px;
  ${BREAKPOINTS.max.medium`
    ${columns(5, 8)};
  `}
  ${BREAKPOINTS.max.small`
    ${columns(null, 12)};
    flex-direction:column;
    align-items:center;
  `}
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  flex: 1;
  ${BREAKPOINTS.max.small`
    ${Item} {
      text-align:center;
    }
    ${Heading} {
      text-align:center;
    }
    ${Links} {
      justify-content: center;
    }
  `}
`;

const StyledLink = styled(Link)`
  width: 18px;
  height: 18px;
  & svg {
    width: 100%;
    height: auto;
  }
  &:hover {
    opacity: 0.8;
  }
`;

const HiddenLabel = styled.span`
  position: absolute;
  opacity: 0;
  pointer-events: none;
  width: 0;
  height: 0;
  overflow: hidden;
`;

const getSocialIcon = (name: string) => {
  switch (name.toLowerCase()) {
    case 'instagram':
      return <InstagramIcon />;
    case 'linkedin':
      return <LinkedinIcon />;
    case 'youtube':
      return <YoutubeIcon />;
    case 'twitter':
      return <TwitterIcon />;
    default:
      return <p>Social Icon not found</p>;
  }
};

const Footer = () => {
  const [inViewRef] = useInview({ sectionName: 'footer' });
  const { generic } = useContext(PageContext);

  return (
    <Container ref={inViewRef}>
      <Horizontal color={COLORS.black.css} />
      <Content>
        <LogoWrapper>
          <Logo>
            <Link link={{ type: 'internal', url: '/', label: 'Home page' }}>
              <HiddenLabel>Flow logo</HiddenLabel>
              <LogoLight />
            </Link>
          </Logo>
          <Copyright>
            <Markdown content={generic.footerCopyright} />
          </Copyright>
          <SocialLinks>
            {generic.socialLinks.items.map((d) => {
              return (
                <StyledLink link={d} key={d.label}>
                  <HiddenLabel>{d.label}</HiddenLabel>
                  {getSocialIcon(d.label)}
                </StyledLink>
              );
            })}
          </SocialLinks>
        </LogoWrapper>
        <Columns>
          {generic.footer.items.map((column) => (
            <Column key={column.name}>
              <Heading>{column.name}</Heading>
              <Links>
                {column.links.items.map((link) => {
                  if (!link) return null;
                  return (
                    <Item key={link.label}>
                      <Link link={link} />
                      {link.tag && <Tag>{link.tag}</Tag>}
                    </Item>
                  );
                })}
              </Links>
            </Column>
          ))}
        </Columns>
      </Content>
    </Container>
  );
};

export default memo(Footer);
