import {
  useState,
  useEffect,
  useContext,
  useRef,
  MutableRefObject,
} from 'react';
import { AnalyticsContext } from '../components/AnalyticsProvider';

type inViewOptions = {
  once?: boolean;
  threshold?: number;
  triggerOnNoSupport?: boolean;
  sectionName?: string;
};

const useInview = <T extends Element = HTMLDivElement>({
  once = true,
  threshold = 0.5,
  triggerOnNoSupport = true,
  sectionName,
}: inViewOptions): [MutableRefObject<T>, boolean] => {
  const analytics = useContext(AnalyticsContext);
  const [inView, setInview] = useState<boolean>(false);
  const ref = useRef<T>(null);

  useEffect(() => {
    if (inView && sectionName) {
      analytics.track('viewport', { section: sectionName });
    }
  }, [inView, analytics, sectionName]);

  useEffect(() => {
    let observer;
    // no intersectionObserver so element will always be visible
    if ('IntersectionObserver' in window === false) {
      if (triggerOnNoSupport) {
        setInview(true);
      }
    } else {
      // Listen to viewport updates for element
      observer = new IntersectionObserver((entries) => {
        entries.forEach(
          (entry) => {
            const { isIntersecting } = entry;

            if (!isIntersecting && inView && !once) {
              setInview(false);
            }
            if (isIntersecting && !inView) {
              setInview(true);
            }
          },
          {
            threshold,
          }
        );
      });

      // start observing
      if (ref.current) {
        observer.observe(ref.current);
      }
    }

    // Stop observing when object is destroyed
    return function unbind() {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [ref, inView, once, threshold, triggerOnNoSupport]);

  return [ref, inView];
};

export default useInview;
