import React, { memo } from 'react';
import styled, { css } from 'styled-components';

import Link from './Link';

import TYPO from '../styles/typography';
import COLORS from '../styles/colors';

import { ReactComponent as ChevronDown } from '../assets/icons/chevron-down.svg';
import { NavigationLinkFragment } from '../schemas/api';
import { Tag } from '../styles/tag';

const Dropdown = styled.ul`
  position: absolute;
  top: 100%;
  left: 50%;
  background-color: ${COLORS.white.css};
  opacity: 0;
  z-index: 1;
  transition: all 0.2s ease;
  pointer-events: none;
  padding: 6px;
  min-width: 192px;
  border-radius: 3px;
  transform: translateX(-50%) translateY(-10px);
  box-shadow: 0 4px 10px 5px ${COLORS.black.opacity(0.05)};
`;

const itemStyle = css`
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  gap: 6px;
  align-items: center;
  padding: 12px;
`;

type ItemProps = {
  $active: boolean;
};
const SubItem = styled.li<ItemProps>`
  display: flex;
  align-items: center;
  gap: 3px;
  list-style: none;
  width: 100%;
  ${TYPO.small}
  font-size: 12px;
  border-bottom: 1px solid ${COLORS.shades.s200.css};
  border-radius: 3px;
  position: relative;
  color: ${(props) =>
    props.$active ? COLORS.brand.regular.css : COLORS.black.css};

  & > a {
    ${itemStyle}
    &:hover {
      color: inherit;
    }
  }

  &:hover {
    background: ${COLORS.brand.regular.css};
    color: ${COLORS.white.css};
  }
  &:last-of-type {
    border-bottom: none;
  }
`;

const Group = styled.span`
  ${itemStyle}
`;

const Item = styled.li<ItemProps>`
  position: relative;
  ${TYPO.nav}
  white-space: nowrap;
  cursor: default;
  color: ${(props) =>
    props.$active ? COLORS.brand.regular.css : COLORS.black.css};
  & > a {
    ${itemStyle}
  }

  &:hover {
    & ${Dropdown} {
      opacity: 1;
      pointer-events: all;
      transform: translateX(-50%) translateY(0px);
    }
  }
  &:after {
    content: '';
    opacity: 0;
    position: absolute;
    bottom: -12px;
    left: calc(50% - 2px);
    width: 4px;
    height: 4px;
    background: ${COLORS.black.css};
    border-radius: 4px;
    transition: opacity 0.2s ease;
  }

  &:hover:after {
    opacity: 1;
  }
`;

type NavigationItemProps = {
  link: NavigationLinkFragment;
  route?: string;
};

const NavigationItem = ({ route, link }: NavigationItemProps) => {
  if (!link) return null;
  const subMenu = link.items?.items;
  const hasSubmenu = subMenu && subMenu.length > 0;

  if (hasSubmenu) {
    return (
      <Item $active={subMenu.some((e) => route === e?.url)}>
        <Group>
          <span>{link.label}</span>
          {link.tag && <Tag>{link.tag}</Tag>}
          <ChevronDown width="12" height="12" />
        </Group>
        <Dropdown>
          {subMenu.map((subLink) => {
            if (!subLink) return null;
            return (
              <SubItem key={subLink.label} $active={route === subLink?.url}>
                <Link link={subLink}>
                  <span>{subLink.label}</span>
                  {subLink.tag && <Tag>{subLink.tag}</Tag>}
                </Link>
              </SubItem>
            );
          })}
        </Dropdown>
      </Item>
    );
  }
  return (
    <Item $active={route === link.url}>
      <Link link={link}>
        <span>{link.label}</span>
        {link.tag && <Tag>{link.tag}</Tag>}
      </Link>
    </Item>
  );
};

export default memo(NavigationItem);
