import React, { createContext, useEffect, useMemo, useCallback } from 'react';
import { AnalyticsBrowser } from '@segment/analytics-next';
import Router from 'next/router';
import { useLocalStorage } from 'react-use';

// add analytics type to the window object
declare global {
  interface Window {
    analytics: any;
  }
}

type IdentifyResponse = {
  success?: boolean;
  error?: boolean;
};

type AnalyticsActions = {
  track: (name: string, properties?: Record<string, unknown>) => void;
  identify: (data: Record<string, unknown>) => Promise<IdentifyResponse>;
  askConsent: boolean;
  acceptsCookies: (value: '1' | '0') => void;
};
export const AnalyticsContext = createContext<AnalyticsActions | null>(null);

type AnalyticsProviderProps = {
  segmentId: string;
  children: React.ReactNode;
};
export default function AnalyticsProvider({
  segmentId,
  children,
}: AnalyticsProviderProps) {
  const [consent, setConsent] = useLocalStorage<'1' | '0' | undefined>(
    'accept_cookies'
  );

  const analytics = React.useMemo(
    () => AnalyticsBrowser.load({ writeKey: segmentId }),
    [segmentId]
  );

  const identify = useCallback(
    async (data: Record<string, unknown>) => {
      try {
        if (process.env.BUILD_ENV !== 'local') {
          analytics.identify(data.email as string, data, {
            integrations: {
              MailChimp: {
                subscriptionStatus: 'subscribed',
              },
            },
          });

          return { success: true };
        }

        console.info('[DEBUG] event:', 'identify', data.email, data);
        return { success: true };
      } catch (e) {
        console.warn('Error while trying to identify', e);
        return { error: true };
      }
    },
    [analytics]
  );

  const track = useCallback(
    (eventName: string, properties: any) => {
      if (process.env.BUILD_ENV === 'production') {
        try {
          // track only if the user didn't specifically deny the consent
          if (consent !== '0') analytics.track(eventName, properties);
        } catch (e) {
          console.warn('analytics not working', e);
        }
      } else {
        console.info('[DEBUG] event:', 'track', eventName, properties);
      }
    },
    [analytics, consent]
  );

  // Track page views
  useEffect(() => {
    const pageTrack = (url: string) => {
      analytics.pageView(url);
    };
    // Track client-side page views with Segment
    Router.events.on('routeChangeComplete', pageTrack);
    return () => {
      Router.events.off('routeChangeComplete', pageTrack);
    };
  }, [analytics]);

  const value = useMemo<AnalyticsActions>(() => {
    return {
      track,
      identify,
      askConsent: consent === undefined,
      acceptsCookies: setConsent,
    };
  }, [identify, consent, setConsent, track]);

  return (
    <AnalyticsContext.Provider value={value}>
      {children}
    </AnalyticsContext.Provider>
  );
}
