import React, { useContext } from 'react';
import Link, { LinkProps } from 'next/link';
import { PageContext } from './PageProvider';

import type { LinkFragment } from '../schemas/api';

interface LinkJSXProps extends Omit<LinkProps, 'href'> {
  link: LinkFragment;
  children?: React.ReactNode;
}

function LinkJSX({
  link,
  children,
  prefetch,
  passHref,
  replace,
  scroll,
  shallow,
  locale,
  ...props
}: LinkJSXProps) {
  const { generic } = useContext(PageContext);

  if (!link) {
    return <>{children}</>;
  }

  /* eslint-disable react/jsx-no-target-blank */
  if (link.type === 'external') {
    return (
      <a
        href={link.url}
        target="_blank"
        rel={link.referrer ? 'noopener' : 'noreferrer nofollow'}
        {...props}
      >
        {children || link.label}
      </a>
    );
  }
  /* eslint-enable react/jsx-no-target-blank */
  if (link.type === 'mailto') {
    return (
      <a href={`mailto:${generic.email}`} {...props}>
        {children || link.label}
      </a>
    );
  }

  return (
    <Link
      href={link.url}
      as={link.url}
      passHref={passHref}
      prefetch={prefetch}
      replace={replace}
      scroll={scroll}
      shallow={shallow}
      locale={locale}
      {...props}
    >
      {children || link.label}
    </Link>
  );
}

export default LinkJSX;
