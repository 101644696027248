/* eslint-disable import/prefer-default-export */
import styled, { css } from 'styled-components';

import COLORS from './colors';

const RectStyle = css`
  display: flex;
  align-items: center;

  font-weight: 600;
  font-size: 13px;

  &:disabled,
  &[disabled] {
    pointer-events: none;
  }

  &:not(:has(svg)) {
    padding-right: 12px;
  }

  cursor: pointer;

  svg {
    flex: 0 0 auto;
  }
  flex: 0 0 auto;
  white-space: nowrap;
`;

export type ButtonSize = 'default' | 'small' | 'big';

type RectProps = {
  $variant?: 'default' | 'secondary' | 'light';
  $shape?: 'default' | 'rounded';
  $size?: ButtonSize;
};

const Button = styled.button<RectProps>`
  ${RectStyle}

  ${(props) => {
    switch (props.$shape) {
      case 'rounded':
        return css`
          border-radius: 50px;
        `;
      default:
        return css`
          border-radius: 3px;
        `;
    }
  }}
  
  ${(props) => {
    switch (props.$size) {
      case 'small':
        return css`
          padding-left: 6px;
          padding-right: 12px;
          min-height: 30px;
          gap: 6px;
          font-weight: 600;
          font-size: 13px;
        `;
      case 'big':
        return css`
          padding-left: 16px;
          padding-right: 16px;
          min-height: 42px;
          gap: 6px;
          font-weight: 600;
          font-size: 16px;
          border-radius: 4px;
          svg {
            width: 16px;
            height: 16px;
          }
        `;
      default:
        return css`
          padding-left: 12px;
          padding-right: 24px;
          min-height: 36px;
          gap: 12px;
          font-weight: 600;
          font-size: 13px;
        `;
    }
  }}

  ${(props) => {
    switch (props.$variant) {
      case 'light':
        return css`
          background: ${COLORS.blocks.background.css};
          color: ${COLORS.shades.s500.css};
          border: 1px solid ${COLORS.shades.s250.css};
          &:hover {
            background: ${COLORS.blocks.background.mix(
              COLORS.black,
              0.03
            ) as string};
          }
          &:active {
            background: ${COLORS.blocks.background.mix(
              COLORS.black,
              0.05
            ) as string};
          }
          &:disabled,
          &[disabled] {
            border: 1px solid ${COLORS.shades.s250.css};
            color: ${COLORS.shades.s300.css};
          }
          &[aria-expanded='true'] {
            outline: 2px solid ${COLORS.brand.light.css};
          }
        `;
      case 'secondary':
        return css`
          background: transparent;
          color: ${COLORS.brand.regular.css};
          border: 1px solid ${COLORS.brand.regular.css};
          &:hover {
            background: ${COLORS.brand.regular.mix(
              COLORS.white,
              0.95
            ) as string};
          }
          &:active {
            background: ${COLORS.brand.regular.opacity(0.1)};
          }
          &:disabled,
          &[disabled] {
            border: 1px solid ${COLORS.shades.s250.css};
          }
          &[aria-expanded='true'] {
            outline: 2px solid ${COLORS.brand.light.css};
          }
        `;
      default:
        return css`
          background: ${COLORS.brand.regular.css};
          color: ${COLORS.white.css};
          &:hover {
            background: ${COLORS.brand.regular.mix(
              COLORS.black,
              0.2
            ) as string};
          }
          &:active {
            background: ${COLORS.brand.regular.mix(
              COLORS.black,
              0.4
            ) as string};
          }
          &:disabled,
          &[aria-expanded='true'] {
            outline: 2px solid ${COLORS.brand.light.css};
          }
        `;
    }
  }}
`;

export default Button;
