import React from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';

import COLORS from '../styles/colors';
import TYPO from '../styles/typography';

// applies some very low key styling

type ContentProps = {
  $brandColors: boolean;
};
const Content = styled.div<ContentProps>`
  p,
  li {
    ${TYPO.p1}
    margin-top: 0;
    margin-bottom: 1em;

    &:last-child {
      margin-bottom: 0;
    }
  }
  strong {
    color: ${(props) =>
      props.$brandColors ? COLORS.brand.regular.css : 'inherit'};
    font-weight: normal;
  }
  em {
    /* italic: todo */
  }
  a {
    text-decoration: none;
    color: ${COLORS.brand.regular.css};
    font-weight: 500;
    &:hover {
      text-decoration: underline;
    }
  }
`;

type MarkdownProps = {
  content: string;
  className?: string;
  brandColors?: boolean;
};

function Markdown({
  content,
  className,
  brandColors = true,
  ...props
}: MarkdownProps) {
  return (
    <Content className={className} $brandColors={brandColors}>
      <ReactMarkdown {...props}>{content}</ReactMarkdown>
    </Content>
  );
}

export default Markdown;
