import styled, { css } from 'styled-components';

const PATTERN = [1, 2];

export enum Direction {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical',
}
export const generate = (
  color: string,
  direction = Direction.HORIZONTAL,
  pattern = PATTERN
) => css`
  ${direction === Direction.VERTICAL ? 'width' : 'height'}: 1px;
  background: repeating-linear-gradient(
    to ${direction === 'vertical' ? 'bottom' : 'right'},
    ${color} 0,
    ${color} ${pattern[0]}px,
    transparent ${pattern[0]}px,
    transparent ${pattern[0] + pattern[1]}px
  );
`;

type DividerProps = {
  $color?: string;
};
// ts-unused-exports:disable-next-line
export const Vertical = styled.div<DividerProps>`
  position: absolute;
  height: 100%;
  width: 1px;
  ${(props) => generate(props.$color, Direction.VERTICAL)}
`;

export const Horizontal = styled.div<DividerProps>`
  position: absolute;
  width: 100%;
  height: 1px;
  ${(props) => generate(props.$color, Direction.HORIZONTAL)}
`;
