import React from 'react';

import dynamic from 'next/dynamic';
import type { PageGenericFragment } from '../schemas/api';

import Footer from './Footer';

import PageProvider from './PageProvider';
import AnalyticsProvider from './AnalyticsProvider';

const CookieBanner = dynamic(() => import('./CookieBanner'), { ssr: false });

type PageProps = {
  children: React.ReactNode;
  generic: PageGenericFragment;
};

export default function Page({ children, generic }: PageProps) {
  return (
    <PageProvider generic={generic}>
      <AnalyticsProvider segmentId={generic.segmentId}>
        <CookieBanner />
        {children}
        <Footer />
      </AnalyticsProvider>
    </PageProvider>
  );
}
