import React, {
  memo,
  useMemo,
  createContext,
  useState,
  useEffect,
  useContext,
} from 'react';
import Router from 'next/router';

import type {
  PageGenericFragment,
  NavigationLinkFragment,
} from '../schemas/api';

type PageContextValue = {
  showMobileNav: boolean;
  toggleMobileNav: () => void;
  navigation: NavigationLinkFragment[];
  generic: PageGenericFragment;
  showCTAPopup: boolean;
  setShowCTAPopup: React.Dispatch<React.SetStateAction<boolean>>;
};

export const PageContext = createContext<PageContextValue | null>(null);

type PageProviderProps = {
  children: React.ReactNode;
  generic: PageGenericFragment;
};

function PageProvider({ children, generic }: PageProviderProps) {
  const [showMobileNav, setShowMobileNav] = useState(false);
  const [showCTAPopup, setShowCTAPopup] = useState(false);

  useEffect(() => {
    const start = () => {
      setShowMobileNav(false);
    };

    Router.events.on('routeChangeStart', start);

    return () => {
      Router.events.off('routeChangeStart', start);
    };
  }, []);

  const value = useMemo<PageContextValue>(
    () => ({
      showMobileNav,
      toggleMobileNav: () => setShowMobileNav(!showMobileNav),
      navigation: generic.navigation.items,
      generic,
      showCTAPopup,
      setShowCTAPopup,
    }),
    [showMobileNav, generic, showCTAPopup]
  );

  return <PageContext.Provider value={value}>{children}</PageContext.Provider>;
}

export function usePageContext() {
  const context = useContext(PageContext);
  if (!context) throw new Error('Context not found');
  return context;
}

export default memo(PageProvider);
