import React, { memo, useContext } from 'react';
import styled, { css } from 'styled-components';

import { useRouter } from 'next/router';
import Link from './Link';

import NavigationItem from './NavigationItem';
import { PageContext } from './PageProvider';

import COLORS from '../styles/colors';
import BREAKPOINTS from '../styles/breakpoints';
import { Grid, columns } from '../styles/grid';

import LogoLoader from './LogoLoader';
import CTAButtons from './CTAButtons';

const GridContainer = styled(Grid)`
  z-index: 2;
  position: relative;
`;

const Inner = styled.div`
  ${columns(1, 12)};
  width: 100%;
  height: 108px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.2s ease;

  ${BREAKPOINTS.max.small`
  height: 60px;
  `}

  ${BREAKPOINTS.max.medium`
    & .call-to-actions {
      display:none;
    }
  `}
`;

const Background = styled.div`
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: ${COLORS.white.opacity(0.3)};
  transition: all 0.2s ease;

  @supports (backdrop-filter: blur(10px)) {
    backdrop-filter: blur(10px);
    background: ${COLORS.white.opacity(0.1)};
  }

  mask-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 1) 65%,
    rgba(0, 0, 0, 0) 100%
  );
`;

type ContainerProps = {
  $small?: boolean;
  $showMobileNav?: boolean;
};

const Container = styled.nav<ContainerProps>`
  display: block;
  position: sticky;
  top: 0px;
  left: 0;
  width: 100%;

  z-index: 9;
  ${(props) => {
    if (props.$showMobileNav) {
      return css`
        & ${Background} {
          background: transparent;
        }
      `;
    }
    return css``;
  }}
`;

type LogoProps = {
  $show: boolean;
};
const Logo = styled.div<LogoProps>`
  flex: 0 0 auto;
  width: 86px;
  height: 100%;
  opacity: ${(props) => (props.$show ? 1 : 0)};
  transition: opacity 0.2s ease;

  & svg {
    width: 100%;
    height: 100%;
  }
  ${BREAKPOINTS.min.small`
    opacity:1;
    `}
`;

const NavItems = styled.ul`
  display: flex;
  gap: 10px;
`;

const Items = styled.ul`
  display: flex;
  flex-direction: flex-end;
  align-items: center;
  gap: 42px;

  & .call-to-actions {
    padding: 0;
  }
  ${BREAKPOINTS.max.small`
    display: none;
    `}
`;

type MobileToggleProps = {
  $close: boolean;
};
const MobileToggle = styled.div<MobileToggleProps>`
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 32px;
  height: 20px;
  cursor: pointer;

  ${BREAKPOINTS.max.small`
    display: flex;
    `}

  div {
    width: 100%;
    height: 2px;
    background: ${(props) =>
      props.$close ? COLORS.white.css : COLORS.black.css};
    transition: opacity 0.2s ease, transform 0.2s ease;
    transform-origin: center center;
  }

  ${(props) =>
    props.$close &&
    css`
      div:first-of-type {
        transform: rotate(45deg) translateY(6px);
      }
      div:nth-of-type(2) {
        transform: rotate(-45deg) translateY(-6px);
      }
      div:last-of-type {
        opacity: 0;
      }
    `}
`;

const HiddenLabel = styled.span`
  position: absolute;
  opacity: 0;
  pointer-events: none;
  width: 0;
  height: 0;
  overflow: hidden;
`;

const Navigation = () => {
  const {
    toggleMobileNav,
    showMobileNav,
    navigation: items = [],
    generic,
  } = useContext(PageContext);
  const { asPath } = useRouter();

  const index = asPath.lastIndexOf('/');
  const route = asPath.substring(index === -1 ? 0 : index);

  return (
    <Container $showMobileNav={showMobileNav}>
      <GridContainer>
        <Inner>
          <Logo $show={!showMobileNav}>
            <Link link={{ type: 'internal', url: '/', label: 'Home page' }}>
              <HiddenLabel>Flow logo</HiddenLabel>
              <svg
                width="130"
                height="80"
                viewBox="0 0 86 34"
                preserveAspectRatio="xMinYMid meet"
              >
                <LogoLoader size={32} x={1} y={1} strokeWidth={4} />
                <svg
                  width="39"
                  height="16"
                  viewBox="0 0 39 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  x="47"
                  y="9"
                >
                  <path
                    d="M0.283539 15.0734H2.21239V8.95502H7.29377V7.15061H2.21239V2.58773H8.62116V0.78332H0.283539V15.0734Z"
                    fill="#242323"
                  />
                  <path
                    d="M9.79023 15.0734H11.6776V0.119629H9.79023V15.0734Z"
                    fill="#242323"
                  />
                  <path
                    d="M13.1536 10.1994C13.1536 11.7135 13.6513 12.9372 14.6261 13.8705C15.6009 14.8038 16.8039 15.2808 18.2142 15.2808C19.6246 15.2808 20.8275 14.8038 21.8023 13.8705C22.7978 12.9164 23.2956 11.6927 23.2956 10.1994C23.2956 8.70613 22.7978 7.50319 21.823 6.56988C20.8482 5.63656 19.6453 5.18027 18.2142 5.18027C16.7831 5.18027 15.5802 5.63656 14.6054 6.56988C13.6306 7.50319 13.1536 8.70613 13.1536 10.1994ZM21.4082 10.1994C21.4082 11.1742 21.0971 11.9624 20.4749 12.5638C19.8734 13.1653 19.106 13.4557 18.2142 13.4557C17.3224 13.4557 16.555 13.1653 15.9328 12.5638C15.3313 11.9624 15.0202 11.1742 15.0202 10.1994C15.0202 9.24538 15.3313 8.47799 15.9328 7.89726C16.555 7.31653 17.3224 7.02616 18.2142 7.02616C19.106 7.02616 19.8734 7.31653 20.4749 7.89726C21.0971 8.47799 21.4082 9.24538 21.4082 10.1994Z"
                    fill="#242323"
                  />
                  <path
                    d="M38.961 5.38768H36.9492L34.5226 12.1698L32.096 5.38768H30.2708L27.8442 12.1698L25.4176 5.38768H23.4058L27.0976 15.0734H28.6323L31.1834 7.95948L33.8174 15.0734H35.373L38.961 5.38768Z"
                    fill="#242323"
                  />
                </svg>
              </svg>
            </Link>
          </Logo>
          <Items>
            <li>
              <NavItems>
                {items.map((link) => {
                  return (
                    <NavigationItem
                      key={link.label}
                      route={route}
                      link={link}
                    />
                  );
                })}
              </NavItems>
            </li>
            <li>
              <CTAButtons links={generic.actions.items} />
            </li>
          </Items>
          <MobileToggle onClick={toggleMobileNav} $close={showMobileNav}>
            <div />
            <div />
            <div />
          </MobileToggle>
        </Inner>
      </GridContainer>
      <Background />
    </Container>
  );
};

export default memo(Navigation);
